import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import server from '../../../config/server';
import { CircularProgress, FormControl, Grid, NativeSelect } from '@material-ui/core';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import { KeyboardDatePicker } from '@material-ui/pickers';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';
import StyledButton from '../../../components/StyledButton/StyledButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    toolbar: {
      borderBottom: '1px solid rgba(0,0,0,.15)'
    },
    flex: {
      flexGrow: 1
    },
    tableContainer: {
      marginTop: theme.spacing(4)
    },
    form: {
      marginTop: theme.spacing(4)
    },
    textField: {
      marginBottom: theme.spacing(2)
    },
    progress: {
      marginLeft: theme.spacing(2)
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2)
    },
    datePickerFields: {
      marginRight: theme.spacing(2),
      '& label': {
        fontFamily: 'Teko'
      },
      '& input': {
        color: 'white'
      },
      '& fieldset': {
        borderColor: 'white',
        color: 'white',
      },
    },
    responseContainer: {
      marginTop: theme.spacing(2)
    },
    formControl: {
      marginBottom: theme.spacing(2)
    }
  })
);

interface IState {
    startDate: string;
    endDate: string;
    hasMaxEntries: boolean;
    multiplier: number,
    giveawayType: string;
}

const CreatePromotionView: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const [formState, setFormState] = useState<IState>({
        giveawayType: 'beginner',
        startDate: new Date().toString(),
        endDate: new Date().toString(),
        multiplier: 1,
        hasMaxEntries: false

    });

    const [isLoading, setIsLoading] = useState(false);

    const handleTypeChange = (event: React.ChangeEvent<{value: string | unknown}>) => {
        const value = event.target.value as string;
        setFormState({...formState, giveawayType: value});
    };

    const handleDateChange = (field: string) => {
        return (date: Date | null) => {
            if (date) {
                date.setHours(0,0,0,0);
            }
            setFormState({ ...formState, [field]: date});
        };
    };

    const handleMaxEntriesChange = (event: React.ChangeEvent<{value: boolean | unknown}>) => {
        const value = event.target.value as boolean;
        setFormState({ ...formState, hasMaxEntries: value });
    };

    const handleMultiplierChange = (event: React.ChangeEvent<{ value: number | unknown }>) => {
        const value = event.target.value as number;
        setFormState({ ...formState, multiplier: value });
    };

    const handleSubmit = async () => {
        if (new Date(formState.startDate) > new Date(formState.endDate)) {
            alert('The start date must be before the end date');
        } else if (formState.multiplier < 1) {
            alert('The multiplier may not be 0');
        } else {
            try {
                setIsLoading(true);
                await server.post('/admin/promotions', formState);
                setIsLoading(false);
                // success
                history.push('/admin/manage-promotions');
            } catch (e) {
                setIsLoading(false);
                console.log(e.response.data.message);
            }
        }
    };

    return (
        <div className={classes.root}>
            <Grid container justify='center'>
                <Grid item xs={12} md={8}>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <FormControl className={classes.formControl} fullWidth>
                            <NativeSelect
                                variant='outlined'
                                value={formState.giveawayType}
                                onChange={handleTypeChange}
                                inputProps={{name: 'type', id: 'type'}}
                                input={<StyledSelect/>}
                            >
                                <option value={''}></option>
                                <option value={'tiered'}>Tiered</option>

                            </NativeSelect>
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label="Start Date"
                                inputVariant="outlined"
                                autoOk={true}
                                value={formState.startDate}
                                onChange={handleDateChange('startDate')}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                className={classes.datePickerFields}
                            />
                            </FormControl>
                            <FormControl className={classes.formControl} fullWidth>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="date-picker-inline"
                                label="End Date"
                                inputVariant="outlined"
                                autoOk={true}
                                value={formState.endDate}
                                onChange={handleDateChange('endDate')}
                                KeyboardButtonProps={{
                                'aria-label': 'change date',
                                }}
                                autoFocus={true}
                                className={classes.datePickerFields}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth>
                            <StyledTextField
                                name='multiplier'
                                label='Multiplier'
                                onChange={handleMultiplierChange}
                                value={formState.multiplier}
                                variant='outlined'
                                
                            />
                        </FormControl>
                        <FormControl className={classes.formControl} fullWidth>
                            <NativeSelect
                                variant='outlined'
                                value={formState.hasMaxEntries}
                                onChange={handleMaxEntriesChange}
                                inputProps={{name: 'isMaxEntries', id: 'isMaxEntries'}}
                                input={<StyledSelect/>}
                            >
                                <option value={'false'}>Max Entries Disabled</option>
                                <option value={'true'}>Max Entries Enabled</option>

                            </NativeSelect>
                        </FormControl>
                        <div className={classes.actionContainer}>
                            <StyledButton onClick={handleSubmit}>Create Promotion</StyledButton>
                            {isLoading && <CircularProgress size={24} className={classes.progress}/>}
                        </div>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreatePromotionView;